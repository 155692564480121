@tailwind base;
@tailwind components;
@tailwind utilities;


.body{
  font-family: 'Inter', sans-serif !important;
}
.background-gradient{
    background: linear-gradient(142.17deg, #026C66 -10.13%, #00ADA3 60.97%);
}


.border-gradient{
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(142.17deg, #026C66 -10.13%, #00ADA3 60.97%);
  border-image-slice: 1;
  border-radius: 3px;
}
.banner-bg{
  background-color: #07514C;
  padding-top: 65px;
}

/* Banner Css */
.banner-text{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 80px;
  letter-spacing: 0.05em;
}

/* key features */
.gray-bg{
  background: #F9FAFB;
}

.bg-svg-pos {
  top: 35%;
  left: -15%;
}

.scroller-image{
  width: 125px;
  height: 125px;
  background: #E9F8F7;
}

/* contact features */
.contact-img {
  bottom: 85px;
  right: 35px;
  width: 185px;
}

.featureContent-gray-bg{
  background-color: #f5f5f5;
}
/* PRicing */
.check-icon{
  color: #10B981;
}
.popular-border{
  border: 3px solid #00ADA3;
}

/* modal css */
.modal-max-width{
  max-width: 950px;
}

.left-pattern {
  bottom: -170px;
  left: -260px;
}

.right-pattern {
  top: 100px;
  right: -250px;
}
.scan-image{
  width: 155px;
}

.top-negative{
  top:-75px;
}

.border-gradient-color{
  border-image: linear-gradient(142.17deg, #026C66 -10.13%, #00ADA3 60.97%);
  border-image-slice: 1;
}

.Nav-active {
  background: rgb(81 179 173 / 17%);
  color: #3baca6;
}

.notfi-banner-left{
  width: 50%;
  float: left;
  max-width: 100% !important;
 }
 .notfi-banner-right{
  width: 50%;
  float: left;
  max-width: 100% !important;
  }

  .feat-content-small{
    display: none;
  }

/* Media query */

@media (max-width: 1024px) {
 .notfi-banner-left{
  width: 100%;
  max-width: 100% !important;
  text-align: left !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
 }
 .notfi-banner-right{
  width: 100%;
  max-width: 100% !important;
  text-align: left !important;
  }
.feat-content-pad{
  margin-right: 2rem;
}
.contact-img{
  display: none;
}
}

@media (max-width: 900px) {
  .scroller-image{
    float: left !important;
    margin-top: 1rem;
  }
  .benefit-content{
    width: 100% !important;
  }   
  .simplfy-list{
    width: 100% !important;
    padding-top: 0px !important;
  }
}

@media (max-width: 720px) {
  .banner-text{
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 0.05em;
  } 
  .feat-content-small{
    display: block;
  }   
  .feat-content-large{
    display: none;
  }
  .about-ensure{
    flex-direction: column;
  }
  .about-ensure-left{
    width: 100% !important;
  }
  .about-ensure-right{
    width: 100% !important;
  }
  .simplfy-img{
    width: 100% !important;
    margin-top: 15px !important;
  } 
  .download-image{
    display: none;
  }
  .download-content{
    display: block;
    width: 100% !important;
  }
  
}

.small-footer-menu{
  display: none;
}


@media (max-width: 500px) {
  .banner-text{
    font-size: 34px;
    line-height: 54px;
    letter-spacing: 0.05em;
  } 
     
}

@media (max-width: 760px) {
  .small-footer-menu{
    display: block;
  }
  .footer-menu{
    display: none;
  }
}

.theme-text-color{
    color: #179c93;
}


.banner-top-margin{
  margin-top: 65px;
}